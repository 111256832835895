<template>
	<div class="wrap">
		<div class="flex justify-between">
			<el-form :inline="true" :model="searchForm">
<!--				<el-form-item label="关键字">-->
<!--					<el-input v-model="searchForm.search" placeholder="姓名/门店名/手机号码" @submit.native.prevent></el-input>-->
<!--				</el-form-item>-->
<!--				<el-form-item label="打卡时间">-->
<!--					<el-date-picker-->
<!--							v-model="changeDate"-->
<!--							type="month"-->
<!--							@change="getDate"-->
<!--							value-format="yyyy-MM"-->
<!--							placeholder="选择月">-->
<!--					</el-date-picker>-->
<!--				</el-form-item>-->
<!--				<el-form-item>-->
<!--					<el-button type="primary" icon="el-icon-search" @click="loadData()">查询</el-button>-->
<!--				</el-form-item>-->
				<el-form-item>
					<el-button type="primary" @click="dialogFormVisible = true">添加/更新会员打卡码</el-button>
					<span style="margin: 0 15px" v-if="record.code">本次打卡码:{{record.code}}</span>
					<span v-if="record.updated_at">修改时间:{{record.updated_at}}</span>
				</el-form-item>
			</el-form>
		</div>

		<el-table :data="tableData" v-loading="loading" max-height="9999">
			<el-table-column type="index" width="50"></el-table-column>
			<!-- <el-table-column prop="member_number" label="会员ID" width="100"></el-table-column> -->
			<el-table-column prop="name" label="卡项名称"></el-table-column>
			<el-table-column prop="countNum" label="购买次数"></el-table-column>
			<el-table-column prop="num" label="库存"></el-table-column>
			<el-table-column label="卡项来源">
				<template slot-scope="scope">
					<div v-if="scope.row.is_platform == 1">平台</div>
					<div v-else>本店</div>
				</template>
			</el-table-column>
			<el-table-column prop="created_at" label="创建时间"></el-table-column>
			<el-table-column label="购买页面">
				<template slot-scope="scope">
					<el-image class="cover"
							:src="scope.row.mini_code"
							:preview-src-list="[scope.row.mini_code]">
					</el-image>
				</template>
			</el-table-column>
			<el-table-column label="查看详情">
				<template slot-scope="scope">
					<el-button type="primary" @click="toDetails(scope.row)">查看详情</el-button>
				</template>
			</el-table-column>
			<el-table-column label="查看购买用户">
				<template slot-scope="scope">
					<el-button type="primary" @click="toGetUser(scope.row)">查看购买用户</el-button>
				</template>
			</el-table-column>
			<el-table-column label="查看兑换码">
				<template slot-scope="scope">
					<el-button type="primary" @click="toLookCode(scope.row)">查看兑换码</el-button>
				</template>
			</el-table-column>
		</el-table>
		<el-dialog :visible.sync="dialogFormVisible" :modal="false">
			<el-form>
				<el-form-item label="会员打卡码">
					<el-input v-model="clockedCode" maxlength="6" minlength="6" placeholder="请输入会员打卡码"></el-input>
				</el-form-item>
			</el-form>
			<div slot="footer" class="dialog-footer">
				<el-button @click="dialogFormVisible = false">取 消</el-button>
				<el-button type="primary" @click="clocked">确 定</el-button>
			</div>
		</el-dialog>
		<div class="text-center margin-top">
			<el-pagination @current-change="pageChange" :current-page="searchForm.page" :page-sizes="vuex_page_sizes"
				:page-size="searchForm.page_size" layout="total, sizes, prev, pager, next, jumper" :total="total"
				@size-change="pageSizeChange">
			</el-pagination>
		</div>
	</div>
</template>

<script>
	import rules from '../../common/common.rules.js'
	export default {
		data() {
			return {
				rules,
				changeDate:'',
				record: {},
				clockedCode:'',
				dialogFormVisible: false,
				loading: false,
				tableData: [],
				searchForm: {
					page: 1,
					page_size: 10
				},
				total: 0
			};
		},
		mounted() {
			this.loadData();
			// vuex 是minxin 进来的 所以在data中访问不到，需要另行设置
			// this.importMember.headers.Authorization = this.vuex_token;
			this.getCode()
		},
		methods: {
			getDate(){
				console.log(this.changeDate)
				this.searchForm.year = this.changeDate.slice(0,4)
				this.searchForm.month = this.changeDate.slice(this.changeDate.length - 2)
				console.log(this.searchForm.year)
				console.log(this.searchForm.month)
			},
			//查看打卡码
			getCode(){
				this.$api.system.getCode().then((res) => {
					console.log(res)
					this.record = res.data
				})
			},
			clocked(){
				if (this.clockedCode.length!=6){
					this.$message.error('打卡码为六位数字'); 
					return false
				}
				this.$api.system.updateCode({
					code:Number(this.clockedCode)
				}).then((res) => {
					console.log(res)
					this.dialogFormVisible = false
					this.getCode()
				})
			},
			toDetails(item){
				this.$router.push(`/clockOn/details/${item.id}`);
			},
			toGetUser(item){
				this.$router.push(`/clockOn/user/${item.id}`);
			},
			toLookCode(item){
				this.$router.push(`/clockOn/lookCode/${item.id}`);
			},
			loadData() {
				this.loading = true;
				this.$api.system.actList(this.searchForm).then().then(res => {
					console.log(res)
					this.loading = false;
					this.tableData = res.data.data;
					this.total = res.data.total;
				});
			},
			pageSizeChange(size) {
				this.searchForm.page_size = size;
			},
			pageChange(page) {
				this.searchForm.page = page;
				this.loadData()
			},
		}
	};
</script>
<style scoped lang="scss">
	.cover {
		width: 40px;
		height: 40px;
		border-radius: 5px;
		margin-right: 5px;
		cursor: pointer;
	}
</style>
